import * as React from "react";

import AuthContent from "../components/Login/AuthContent";
import Cart from "../components/Cart/Cart"
import Layout from "../components/Base/Layout";
import { SEO } from "../components/Base/Seo";

const CartPage = () => {
  return (
    <Layout>
        <Cart />
    </Layout>
  );
}

export default CartPage

export const Head = () => (
  <SEO title="Global Care Supply | Cart" description="Your shopping cart." />
)